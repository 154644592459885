import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainPage = _resolveComponent("MainPage")!
  const _component_spinner_component = _resolveComponent("spinner-component")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MainPage),
    _createVNode(_component_spinner_component, {
      isLoading: _ctx.isLoading,
      fullPage: true
    }, null, 8, ["isLoading"])
  ], 64))
}