import { defineComponent } from 'vue'
import FontAwesomeComponent from '@/components/FontAwesomeComponent/FontAwesomeComponent.vue'
import { IServiceDto } from '@/common/service.dto'
import * as data from '../../content.json'

export default defineComponent({
  name: 'ServicesComponent',
  components: { FontAwesomeComponent },
  setup () {
    const services: IServiceDto[] = data.services
    return {
      services
    }
  }
})
