import { defineComponent, inject, reactive, ref } from 'vue'
import FontAwesomeComponent from '@/components/FontAwesomeComponent/FontAwesomeComponent.vue'
import CustomModalComponent from '@/components/CustomModalComponent/CustomModalComponent.vue'
import { AppServices } from '@/services/app.services'
import { WorkDone } from '@/common/workdone.dto'

export default defineComponent({
  name: 'ContactComponent',
  components: {
    CustomModalComponent,
    FontAwesomeComponent
  },
  inject: ['appServices'],
  setup () {
    const appServices = inject('appServices') as AppServices
    const showSuccessModal = ref<boolean>(false)
    const showFailureModal = ref<boolean>(false)

    const form = reactive({
      name: '',
      email: '',
      subject: '',
      message: ''
    })

    const formInitialState = {
      name: '',
      email: '',
      subject: '',
      message: ''
    }

    // Message submit
    async function submitForm () {
      const myModal = document.getElementById('exampleModal')
      const wd: WorkDone<string> = await appServices.sendEmail(form)
      if (wd && wd.isOk) {
        showSuccessModal.value = true
        if (myModal !== null) myModal.style.display = 'block'
        Object.assign(form, formInitialState)
      } else {
        showFailureModal.value = true
        if (myModal !== null) myModal.style.display = 'block'
      }
    }

    function updateModalDisplayParams () {
      showSuccessModal.value = false
    }

    return {
      submitForm,
      form,
      showSuccessModal,
      showFailureModal,
      updateModalDisplayParams
    }
  }
})
