import { defineComponent } from 'vue'
import { IExperienceDto } from '@/common/experience.dto'
import FontAwesomeComponent from '@/components/FontAwesomeComponent/FontAwesomeComponent.vue'
import * as data from '../../content.json'

const experiences: IExperienceDto[] = data.experiences

export default defineComponent({
  name: 'ExperiencesComponent',
  components: { FontAwesomeComponent },
  setup () {
    return {
      experiences
    }
  }
})
