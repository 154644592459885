import { IContactMessageDto } from '@/common/contact-message.dto'
import { AxiosService } from '@/services/axios.services'
import { WorkDone } from '@/common/workdone.dto'

export class AppServices {
  private readonly apiUrl = process.env.VUE_APP_API_URL
  private axiosService: AxiosService

  constructor (axiosService: AxiosService) {
    this.axiosService = axiosService
  }

  public async sendEmail (contactMessageDto: IContactMessageDto): Promise<WorkDone<string>> {
    return this.axiosService.doHttpCalls<string>(
      () => this.axiosService.axiosInstance.post(this.apiUrl, contactMessageDto)
    )
  }
}
