import { computed, defineComponent, ref } from 'vue'
import FontAwesomeComponent from '@/components/FontAwesomeComponent/FontAwesomeComponent.vue'
export default defineComponent({
  name: 'RatingStarsComponent',
  components: { FontAwesomeComponent },
  props: {
    level: Number,
    label: String
  },
  setup (props) {
    const level = ref<number>(props.level !== undefined ? props.level : 0)
    const starsCount = computed(() => {
      return computeStarsCountFromLevel(level.value)
    })

    // Compute the number of filled and empty stars
    function computeStarsCountFromLevel (level: number) {
      if (level > 5) level = 5
      else if (level < 0) level = 0
      const digits = level.toString().split('.')
      return {
        filledStartsCount: parseInt(digits[0]),
        emptyStartsCount: 5 - parseInt(digits[0])
      }
    }

    return {
      starsCount
    }
  }
})
