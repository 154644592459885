import { defineComponent, ref } from 'vue'
import ProfileComponent from '@/components/ProfileComponent/ProfileComponent.vue'

interface INavBarItem {
  link: string;
  text: string
}

export default defineComponent({
  name: 'HeaderComponent',
  components: { ProfileComponent },
  setup () {
    const navbarItems = ref<INavBarItem[]>([
      {
        link: '#services',
        text: 'Services'
      },
      {
        link: '#skills',
        text: 'Skills'
      },
      {
        link: '#experiences',
        text: 'Experiences'
      },
      {
        link: '#education',
        text: 'Education'
      },
      {
        link: '#resume',
        text: 'Resume'
      },
      {
        link: '#contact',
        text: 'Contact'
      }
    ])
    return {
      navbarItems
    }
  }
})
