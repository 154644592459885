import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "block" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { id: "services" }
const _hoisted_4 = { class: "block" }
const _hoisted_5 = { class: "container" }
const _hoisted_6 = { id: "skills" }
const _hoisted_7 = { class: "block" }
const _hoisted_8 = { id: "experiences" }
const _hoisted_9 = { class: "container" }
const _hoisted_10 = { class: "block" }
const _hoisted_11 = { class: "container" }
const _hoisted_12 = { id: "education" }
const _hoisted_13 = { class: "block" }
const _hoisted_14 = { id: "resume" }
const _hoisted_15 = { class: "container-fluid p-0 contact-block" }
const _hoisted_16 = { class: "container" }
const _hoisted_17 = { id: "contact" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_component = _resolveComponent("header-component")!
  const _component_services_component = _resolveComponent("services-component")!
  const _component_skills_component = _resolveComponent("skills-component")!
  const _component_experiences_component = _resolveComponent("experiences-component")!
  const _component_education_component = _resolveComponent("education-component")!
  const _component_resumes_component = _resolveComponent("resumes-component")!
  const _component_contact_component = _resolveComponent("contact-component")!
  const _component_footer_component = _resolveComponent("footer-component")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_header_component),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("section", _hoisted_3, [
          _createVNode(_component_services_component)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("section", _hoisted_6, [
          _createVNode(_component_skills_component)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("section", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_experiences_component)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("section", _hoisted_12, [
          _createVNode(_component_education_component)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("section", _hoisted_14, [
        _createVNode(_component_resumes_component)
      ])
    ]),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("section", _hoisted_17, [
          _createVNode(_component_contact_component)
        ]),
        _createVNode(_component_footer_component)
      ])
    ])
  ], 64))
}