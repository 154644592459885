import { defineComponent } from 'vue'
import { IEducationDto } from '@/common/education.dto'
import FontAwesomeComponent from '@/components/FontAwesomeComponent/FontAwesomeComponent.vue'
import * as data from '../../content.json'

export default defineComponent({
  name: 'EducationComponent',
  components: { FontAwesomeComponent },
  setup () {
    const educations: IEducationDto[] = data.education
    return {
      educations
    }
  }
})
