import { defineComponent } from 'vue'
import RatingStarsComponent from '../RatingStarsComponent/RatingStarsComponent.vue'
import FontAwesomeComponent from '@/components/FontAwesomeComponent/FontAwesomeComponent.vue'
import { ISkillDto } from '@/common/skill.dto'
import * as data from '../../content.json'

export default defineComponent({
  name: 'SkillsComponent',
  components: {
    RatingStarsComponent,
    FontAwesomeComponent
  },
  setup () {
    const skills: ISkillDto[] = data.skills

    function getImgUrl (skill: ISkillDto) {
      // @typescript-eslint/no-var-requires
      return require(`@/assets/images/${skill.icon}`)
    }

    return {
      skills,
      getImgUrl
    }
  }
})
