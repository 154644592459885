import { defineComponent } from 'vue'
import FontAwesomeComponent from '@/components/FontAwesomeComponent/FontAwesomeComponent.vue'
import CircularBarComponent from '@/components/CircularBarComponent/CircularBarComponent.vue'
import { ISkillDto } from '@/common/skill.dto'
import * as data from '../../content.json'

export default defineComponent({
  name: 'LanguagesComponent',
  components: {
    FontAwesomeComponent,
    CircularBarComponent
  },
  setup () {
    const languages: ISkillDto[] = data.languages
    return { languages }
  }
})
