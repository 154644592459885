import { createApp } from 'vue'
import App from './App.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faLinkedin, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import {
  faAddressCard,
  faBriefcase,
  faBuilding,
  faCalendarDay,
  faEnvelope,
  faGraduationCap,
  faInfoCircle,
  faLanguage,
  faLink,
  faMapMarkerAlt,
  faPhoneAlt,
  faStar,
  faStarHalfAlt,
  faTools,
  faUserGraduate,
  faCheckCircle,
  faTimes,
  faTimesCircle,
  faFile,
  faCopyright,
  faFileDownload,
  faCog,
  faDatabase,
  faCode,
  faPencilRuler,
  faServer,
  faAddressBook,
  faFileAlt,
  faCheck
} from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import { AxiosService } from '@/services/axios.services'
import { AppServices } from '@/services/app.services'
import store from '../src/store'

library.add(
  faGraduationCap, faUserGraduate, faMapMarkerAlt, faCalendarDay, faBuilding, faLink, faWhatsapp, faPhoneAlt,
  faStar, faStarHalfAlt, faLanguage, faTools, faBriefcase, faEnvelope, faAddressCard, faInfoCircle, faLinkedin,
  faCheckCircle, faTimes, faTimesCircle, faFile, faCopyright, faFileDownload, faCog, faDatabase, faCode, faPencilRuler,
  faServer, faAddressBook, faFileAlt, faCheck
)
const axiosService = new AxiosService()
const appServices = new AppServices(axiosService)

const app = createApp(App)
app.use(store)
app.component('font-awesome-icon', FontAwesomeIcon)
app.provide('appServices', appServices)
app.mount('#app')
