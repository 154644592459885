import { defineComponent, onMounted, watch } from 'vue'
import FontAwesomeComponent from '../FontAwesomeComponent/FontAwesomeComponent.vue'

export default defineComponent({
  name: 'CustomModalComponent',
  components: { FontAwesomeComponent },
  emits: ['updateModalDisplayParams'],
  props: {
    showSuccessModal: Boolean,
    showFailureModal: Boolean
  },
  setup (props, { emit }) {
    let myModal: HTMLElement | null

    // Event listener on windows click
    window.onclick = (event: MouseEvent) => {
      if (event && event.target === myModal) closeModal()
    }

    // Get the modal element after the component is mounted
    onMounted(() => {
      myModal = document.getElementById('myModal')
    })

    function closeModal () {
      if (myModal != null) myModal.style.display = 'none'
      emit('updateModalDisplayParams', { showModal: false })
    }

    function openModal () {
      if (myModal != null) myModal.style.display = 'block'
    }

    watch(() => props.showSuccessModal, (newValue: boolean, oldValue: boolean) => {
      if (newValue === true) openModal()
    })

    watch(() => props.showFailureModal, (newValue: boolean, oldValue: boolean) => {
      if (newValue === true) openModal()
    })

    return {
      closeModal
    }
  }
})
