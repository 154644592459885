import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CircularBarComponent',
  props: {
    level: Number,
    label: String
  },
  setup () {
    return {}
  }
})
