import { defineComponent } from 'vue'
import ProfileComponent from '../../components/ProfileComponent/ProfileComponent.vue'
import ServicesComponent from '@/components/ServicesComponent/ServicesComponent.vue'
import EducationComponent from '../../components/EducationComponent/EducationComponent.vue'
import ExperiencesComponent from '../../components/ExperiencesComponent/ExperiencesComponent.vue'
import SkillsComponent from '../../components/SkillsComponent/SkillsComponent.vue'
import LanguagesComponent from '../../components/LanguagesComponent/LanguagesComponent.vue'
import ResumesComponent from '@/components/ResumesComponent/ResumesComponent.vue'
import ContactComponent from '../../components/ContactComponent/ContactComponent.vue'
import HeaderComponent from '@/components/HeaderComponent/HeaderComponent.vue'
import FooterComponent from '@/components/FooterComponent/FooterComponent.vue'

export default defineComponent({
  name: 'MainPage',
  components: {
    ProfileComponent,
    EducationComponent,
    ExperiencesComponent,
    SkillsComponent,
    LanguagesComponent,
    ResumesComponent,
    ContactComponent,
    HeaderComponent,
    FooterComponent,
    ServicesComponent
  },
  setup () {
    return {}
  }
})
