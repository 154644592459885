import { defineComponent } from 'vue'
import FontAwesomeComponent from '../FontAwesomeComponent/FontAwesomeComponent.vue'
import RatingStarsComponent from '../RatingStarsComponent/RatingStarsComponent.vue'

export default defineComponent({
  name: 'ProfileComponent',
  components: {
    FontAwesomeComponent,
    RatingStarsComponent
  },
  setup () {
    return {}
  }
})
