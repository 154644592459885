import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { WorkDone } from '@/common/workdone.dto'
import store from '../store'

type PortfolioApiServiceHandler = <R> () => Promise<AxiosResponse<WorkDone<R>>>

export class AxiosService {
  public axiosInstance = axios
  // protected store: Store<any>

  constructor () {
    // this.store = useStore()
    this.axiosInstance.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
    //
    this.axiosInstance.interceptors.request.use((axiosRequesConfig: AxiosRequestConfig) => {
      store.commit('increment')
      return axiosRequesConfig
    }, error => {
      store.commit('decrement')
      console.log('The error is the following ', error)
    })

    this.axiosInstance.interceptors.response.use((axiosResponse: AxiosResponse) => {
      store.commit('decrement')
      return axiosResponse
    }, error => {
      store.commit('decrement')
      console.log('The error is the following ', error)
    })
  }

  doHttpCalls<R> (func: PortfolioApiServiceHandler): Promise<WorkDone<R>> {
    return func<R>()
      .then((response: AxiosResponse<WorkDone<R>>) => {
        if (response && response.data && response.data.isOk) {
          return response.data
        } else {
          const message = response && response.data && response.data.error && response.data.error.message ? response.data.error.message : 'Unknown Error'
          return WorkDone.buildError<R>(message)
        }
      }).catch((error: AxiosError) => {
        // display the error toaster
        return WorkDone.buildError<R>(JSON.stringify(error))
      })
  }
}
