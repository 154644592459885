export class WorkDoneError {
  message: string | undefined
}

export class WorkDone<T> {
  isOk: boolean
  data?: T
  error?: WorkDoneError

  private constructor (isOk: boolean) {
    this.isOk = isOk
  }

  public static buildOk<T> (data?: T): WorkDone<T> {
    const wd = new WorkDone<T>(true)
    wd.data = data
    return wd
  }

  public static buildError<T> (message?: string): WorkDone<T> {
    const wd = new WorkDone<T>(false)
    wd.error = { message }
    return wd
  }
}
