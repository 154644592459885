import { defineComponent } from 'vue'
import FontAwesomeComponent from '@/components/FontAwesomeComponent/FontAwesomeComponent.vue'

export default defineComponent({
  name: 'ResumesComponent',
  components: { FontAwesomeComponent },
  setup () {
    return { }
  }
})
