import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createBlock(_component_font_awesome_icon, {
    icon: _ctx.icon,
    color: _ctx.computedColor,
    size: _ctx.computedSize,
    class: "me-2"
  }, null, 8, ["icon", "color", "size"]))
}