import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_component = _resolveComponent("font-awesome-component")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.starsCount.filledStartsCount, (index) => {
      return (_openBlock(), _createBlock(_component_font_awesome_component, {
        key: index,
        icon: ['fa','star'],
        class: "ms-2",
        size: "sm"
      }))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.starsCount.emptyStartsCount, (index) => {
      return (_openBlock(), _createBlock(_component_font_awesome_component, {
        color: "#9a9a9a",
        key: index,
        icon: ['fa','star'],
        class: "ms-2",
        size: "sm"
      }))
    }), 128))
  ], 64))
}