import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'FontAwesomeComponent',
  props: {
    color: String,
    icon: Array,
    size: String
  },
  setup (props) {
    const defaultColor = '#3DA39F'

    const computedColor = computed(() => {
      if (!props.color) return defaultColor
      else return props.color
    })

    const computedSize = computed(() => {
      return props.size ? props.size : 'lg'
    })

    // const computedIcon = computed(() => props.icon)

    return {
      computedColor,
      computedSize
      // computedIcon
    }
  }
})
