
import { defineComponent, computed } from 'vue'
import MainPage from './pages/MainPage/MainPage.vue'
import SpinnerComponent from './components/SpinnerComponent/SpinnerComponent.vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'App',
  components: {
    MainPage,
    SpinnerComponent
  },
  setup () {
    const store = useStore()
    const isLoading = computed(() => store.getters.requestsCount > 0)
    return {
      isLoading
    }
  }
})
