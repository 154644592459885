import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "vld-parent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_loading, {
      active: _ctx.isLoading,
      "onUpdate:active": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isLoading) = $event)),
      loader: "bars",
      color: "#3DA39F",
      opacity: 0.4,
      height: 100,
      width: 100,
      "is-full-page": _ctx.fullPage
    }, null, 8, ["active", "opacity", "is-full-page"])
  ]))
}