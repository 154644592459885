import { createStore } from 'vuex'
import * as getters from './getters'
import * as actions from './actions'
import * as mutations from './mutations'

// Define the initial state
const state = {
  requestsCount: 0
}

export default createStore({
  state,
  getters,
  actions,
  mutations
})
