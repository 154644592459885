import { defineComponent } from 'vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default defineComponent({
  name: 'SpinnerComponent',
  components: { Loading },
  props: {
    isLoading: Boolean,
    fullPage: Boolean
  },
  setup () {
    function onCancel () {
      console.log('User cancelled the loader.')
    }
    return {
      onCancel
    }
  }
})
